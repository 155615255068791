<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>
    <v-card class="page-card-wrapper mt-0">
      <v-card-title class="px-0">{{ $t('text.user') }}</v-card-title>
      <v-card-subtitle class="px-0"
        >{{ $t('text.theUserWhoIsAllowedToOperateTheBackofficeSystem') }}
      </v-card-subtitle>
      <v-form ref="newUserForm">
        <v-divider></v-divider>
        <template v-for="(item, index) in formFields">
          <div :key="index" class="my-2">
            <v-row no-gutters>
              <v-col cols="5" class="pt-6">
                <label class="text-capitalize">
                  {{ $t(`text.${item.label}`) }}
                </label>
              </v-col>
              <v-col cols="7">
                <v-row no-gutters>
                  <v-col :cols="item.cols" :lg="item.lg">
                    <AppFormField
                      ref="formField"
                      v-model="item.model"
                      :outlined="true"
                      :dense="true"
                      :type="item.type"
                      :allowShowPassword="true"
                      :rules="
                        item.key != 'confirmPassword'
                          ? item.rules
                          : validation.confirmPasswordRules(
                              formFields.find((x) => x.key == 'password').model,
                              item.model
                            )
                      "
                      class="pt-3"
                    ></AppFormField>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </template>
        <div class="d-flex justify-end mt-6">
          <v-btn
            depressed
            outlined
            class="text-caption mr-2"
            @click="actionResetForm"
            >{{ $t('text.reset') }}</v-btn
          >
          <v-btn
            depressed
            min-width="100"
            color="primary"
            class="text-caption"
            @click="actionAddForm"
            >{{ $t('text.add') }}</v-btn
          >
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import AppChildPageHeader from '@/components/base/AppChildPageHeader'
import commonMixin from '@/mixins/common.mixin'
import crudMixin from '@/mixins/crud.mixin'
import { FORM_FIELD_TYPE, ROUTE_NAME } from '@/constants'
import { formValidationHelper, i18nHelper } from '@/utils'
import {
  USER_CREATE_USER,
  USER_INITIAL_CREATE_USER_STATE
} from '@/store/user.module'

export default {
  name: 'UserNew',
  components: {
    AppChildPageHeader
  },
  mixins: [commonMixin, crudMixin],
  data: () => ({
    validation: formValidationHelper,
    pageTitle: 'newUser',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'user',
        disabled: false,
        to: { name: ROUTE_NAME.USER_LIST }
      },
      {
        text: 'new',
        disabled: true
      }
    ],
    formFields: [
      {
        model: '',
        label: 'username',
        key: 'username',
        rules: formValidationHelper.usernameRules(),
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'name',
        key: 'name',
        rules: formValidationHelper.requiredRules(),
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'password',
        key: 'password',
        type: FORM_FIELD_TYPE.PASSWORD,
        rules: formValidationHelper.passwordRules(),
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'confirmPassword',
        key: 'confirmPassword',
        type: FORM_FIELD_TYPE.PASSWORD,
        lg: 6,
        cols: 12
      }
    ],
    actions: {
      createRecord: {
        module: 'user',
        state: 'newUser',
        action: USER_CREATE_USER,
        title: i18nHelper.getMessage('text.newUser'),
        successAction: () => {}
      },
      initialCreateRecordState: USER_INITIAL_CREATE_USER_STATE
    }
  }),
  created() {
    this.initUserNew()
  },
  methods: {
    actionAddForm() {
      if (this.$refs.newUserForm.validate()) {
        this.requestCreate(this.mapArrayToObjectData(this.formFields))
      }
    },
    actionResetForm() {
      this.$refs.newUserForm.reset()
    },
    initUserNew() {
      this.actions.createRecord.successAction = () => {
        this.backToParentPage(ROUTE_NAME.USER)
      }
    }
  }
}
</script>

<style></style>
